<template>
  <div>
    <multiselect
      v-model="value"
      :options="params.values"
      :required="true"
      :searchable="true"
      :value="value"
      :multiple="false"
      openDirection="top"
      :show-labels="false"
      :placeholder="$t(`dashboard.reports.form.report.placeholder`)"
      @select="params.stopEditing()"
      ref="autocomplete"
    >
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title" v-html="props.option" />
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Autocomplete",
  components: {
    Multiselect
  },
  data() {
    return {
      value: null
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    }
  },
  created() {
    // this.value = this.getInitialValue();
  },
  mounted() {
    this.value = this.params.value;
    this.$refs.autocomplete.activate();
  }
};
</script>
